import { createWebHashHistory, createRouter } from "vue-router"
import Auth from "@/pages/auth"
import AuthV3 from "@/pages/authV3"
import VoteEnd from "@/pages/event/ev2/voteEnd"
import ShareTrend from "@/pages/share/shareTrend"
import ShareTopic from "@/pages/share/shareTopic"
import ShareArticle from "@/pages/share/shareArticle"
import ShareEvent from "@/pages/share/shareEvent"
import ShareCourse from "@/pages/share/shareCourse"
import ShareFamousTeacherCourse from "@/pages/share/shareFamousTeacherCourse"
import ShareAlbum from "@/pages/share/shareAlbum"
import Player from "@/pages/audio/player"
import CeremonySignUp from "@/pages/event/ev2/ceremonySignUp"
import CourseDetail from "@/pages/courseDetail"
import Home from "@/pages/home"
import EV3Testing from "@/pages/event/ev3/ev3Testing"
import EV3Result from "@/pages/event/ev3/ev3Result"
import Download from "@/pages/share/download"
import ZmyzEnter from "@/pages/event/ev4/zmyzEnter"
import ZmyzAbout from "@/pages/event/ev4/zmyzAbout"
import ShareDynamic from "@/pages/share/shareDynamic"
import ShareGambit from "@/pages/share/shareGambit"
import ShareCircle from "@/pages/share/shareCircle"
import JoinWxGroup from "@/pages/share/joinWxGroup"
import ShareAlbumNative from "@/pages/share/shareAlbumNative"
import ShareTrainingCampTask from "@/pages/share/shareTrainingCampTask"
import ShareTrainingCampClockin from "@/pages/share/shareTrainingCampClockin"
import ShareInstitution from "@/pages/share/shareInstitution"
import ShareDownload from "@/pages/share/shareDownload"
import ShareWorkshopsTask from "@/pages/share/shareWorkshopsTask"

import EduDownload from "@/pages/pck/eduDownload"
import PckDownload from "@/pages/pck/pckDownload"

import CourseCollection from "@/pages/activity/course-collection"
import Rule from "@/pages/activity/course-collection/rule"

import Dgls from "@/pages/activity/dgls"
import DGLSApply from "@/pages/activity/dgls/apply"
import DGLSApplySuccess from "@/pages/activity/dgls/success"

import ChildrenBook100 from "@/pages/activity/100-children-book"
import BookIntroduction from "@/pages/activity/100-children-book/book-introduction"
import BookDetail from "@/pages/activity/100-children-book/bookDetail"

import SCRead from "@/pages/activity/sc-read"

import ShareCollectionCourse from "@/pages/share/shareCollectionCourse"

import GMRead from "@/pages/activity/gm-read"

import LittleReporterApply1 from "@/pages/activity/little-reporter/apply1"
import LittleReporterApply2 from "@/pages/activity/little-reporter/apply2"
import LittleReporterApply1_1 from "@/pages/activity/little-reporter/apply1_1"
import LittleReporterApply2_1 from "@/pages/activity/little-reporter/apply2_1"
import Introduce from "@/pages/activity/little-reporter/introduce"

import BookBoy from "@/pages/activity/book-boy/apply"

import RSIndex from "@/pages/activity/red-story/index"
import RSDisplay from "@/pages/activity/red-story/display"
import RSEnroll from "@/pages/activity/red-story/enroll"
import RSIntroduce from "@/pages/activity/red-story/introduce"
import RSVideoDetail from "@/pages/activity/red-story/videoDetail"
import RSArticleDetail from "@/pages/activity/red-story/articleDetail"
import RSNotice from "@/pages/activity/red-story/notice"

import RS2024Index from "@/pages/activity/red-story-2024/index"
import RS2024Display from "@/pages/activity/red-story-2024/display"
import RS2024Enroll from "@/pages/activity/red-story-2024/enroll"
import RS2024Introduce from "@/pages/activity/red-story-2024/introduce"
import RS2024VideoDetail from "@/pages/activity/red-story-2024/videoDetail"
import RS2024ArticleDetail from "@/pages/activity/red-story-2024/articleDetail"
import RS2024Trends from "@/pages/activity/red-story-2024/trends"
import RS2024TrendsDetail from "@/pages/activity/red-story-2024/trendsDetail"

import carnivalIndex from "@/pages/activity/carnival/index"
import carnivalDisplay from "@/pages/activity/carnival/display"
import carnivalEnroll from "@/pages/activity/carnival/enroll"
import carnivalIntroduce from "@/pages/activity/carnival/introduce"
import carnivalVideoDetail from "@/pages/activity/carnival/videoDetail"
import carnivalArticleDetail from "@/pages/activity/carnival/articleDetail"
import carnivalTrends from "@/pages/activity/carnival/trends"
import carnivalTrendsDetail from "@/pages/activity/carnival/trendsDetail"

import Privacy from "@/pages/privacy/privacy"

import Shop from "@/pages/shop"
import TrainingCampGoods from "@/pages/shop/training-camp"
import TrainingCampCreateOrder from "@/pages/shop/training-camp/createOrder"
import TrainingCampOrderSuccess from "@/pages/shop//training-camp/orderSuccess"

import LenovoEvent from "@/pages/activity/lenovo"
import LenovoEventEnroll from "@/pages/activity/lenovo/enroll"

import OfficialHome from "@/pages/official/home"
import OfficialProfile from "@/pages/official/profile"
import OfficialBusiness from "@/pages/official/business"
import OfficialCasesDigitalize from "@/pages/official/cases/digitalize"
import OfficialCasesEdu from "@/pages/official/cases/edu"

import ArtTeam from "@/pages/art/team"
import ArtVideo from "@/pages/art/video"

export default createRouter({
    history: createWebHashHistory(),
    scrollBehavior() {
        return { top: 0 }
    },
    routes: [
        {
            path: "/",
            name: "Auth",
            component: Auth,
        },
        {
            path: "/authv3",
            name: "AuthV3",
            component: AuthV3,
        },
        {
            path: "/course-detail",
            name: "CourseDetail",
            component: CourseDetail,
        },
        {
            path: "/home",
            name: "Home",
            component: Home,
        },
        {
            path: "/share-trend",
            name: "ShareTrend",
            component: ShareTrend,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/share-topic",
            name: "ShareTopic",
            component: ShareTopic,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/share-article",
            name: "ShareArticle",
            component: ShareArticle,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/share-event",
            name: "ShareEvent",
            component: ShareEvent,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/share-course",
            name: "ShareCourse",
            component: ShareCourse,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/share-ft-course",
            name: "ShareFamousTeacherCourse",
            component: ShareFamousTeacherCourse,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/share-album",
            name: "ShareAlbum",
            component: ShareAlbum,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/voteHome",
            name: "VoteHome",
            component: VoteEnd,
            meta: {
                title: "寻找中国好校长",
            },
        },
        {
            path: "/player",
            name: "Player",
            component: Player,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/ceremony-sign-up",
            name: "CeremonySignUp",
            component: CeremonySignUp,
            meta: {
                title: "现代学校治理研讨会暨第三届寻找中国好校长盛典",
            },
        },
        {
            path: "/ev3-testing",
            name: "EV3Testing",
            component: EV3Testing,
            meta: {
                title: "带娃心累指数",
            },
        },
        {
            path: "/ev3-result",
            name: "EV3Result",
            component: EV3Result,
            meta: {
                title: "带娃心累指数",
            },
        },
        {
            path: "/download",
            name: "Download",
            component: Download,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/zmyz-about",
            name: "ZmyzAbout",
            component: ZmyzAbout,
            meta: {
                title: "“寻找榜样园长”报名入口",
            },
        },
        // 因不可抗因素，enter和about换一下
        {
            path: "/zmyz-enter",
            name: "ZmyzEnter",
            component: ZmyzEnter,
            meta: {
                title: "“寻找榜样园长”报名入口",
            },
        },
        {
            path: "/share-dynamic",
            name: "ShareDynamic",
            component: ShareDynamic,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/share-gambit",
            name: "ShareGambit",
            component: ShareGambit,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/share-circle",
            name: "ShareCircle",
            component: ShareCircle,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/jwg",
            name: "JoinWxGroup",
            component: JoinWxGroup,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/share-album-native",
            name: "ShareAlbumNative",
            component: ShareAlbumNative,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/share-training-camp-task",
            name: "ShareTrainingCampTask",
            component: ShareTrainingCampTask,
            meta: {
                title: "训练营作业",
            },
        },
        {
            path: "/share-training-camp-clockin",
            name: "ShareTrainingCampClockin",
            component: ShareTrainingCampClockin,
            meta: {
                title: "训练营打卡",
            },
        },
        {
            path: "/share-institution",
            name: "ShareInstitution",
            component: ShareInstitution,
            meta: {
                title: "机构",
            },
        },

        {
            path: "/pck/edu-download",
            name: "EduDownload",
            component: EduDownload,
            meta: {
                title: "光明教育家",
            },
        },
        {
            path: "/pck/pck-download",
            name: "PckDownload",
            component: PckDownload,
            meta: {
                title: "小孔雀",
            },
        },
        {
            path: "/activity/course-collection",
            name: "CourseCollection",
            component: CourseCollection,
            meta: {
                title: "课程征集活动",
            },
        },
        {
            path: "/activity/course-collection/rule",
            name: "Rule",
            component: Rule,
            meta: {
                title: "活动规则",
            },
        },
        {
            path: "/activity/dgls",
            name: "Dgls",
            component: Dgls,
            meta: {
                title: '"寻找大国良师"公益活动',
            },
        },
        {
            path: "/activity/dgls/apply",
            name: "DGLSApply",
            component: DGLSApply,
            meta: {
                title: '寻找"大国良师"公益活动',
            },
        },
        {
            path: "/activity/dgls/success",
            name: "DGLSApplySuccess",
            component: DGLSApplySuccess,
            meta: {
                title: "提交成功",
            },
        },
        {
            path: "/activity/100cb",
            name: "book",
            component: ChildrenBook100,
            meta: {
                title: "“百部新书一起读”",
                keepAlive: true,
            },
        },
        {
            path: "/activity/100cb/introduction",
            name: "BookIntroduction",
            component: BookIntroduction,
            meta: {
                title: "“百部新书一起读”",
            },
        },
        {
            path: "/activity/100cb/book-detail",
            name: "BookDetail",
            component: BookDetail,
            meta: {
                title: "“百部新书一起读”活动",
            },
        },
        {
            path: "/activity/sc-read",
            name: "SCRead",
            component: SCRead,
            meta: {
                title: "三叁阅读能力测评助力书香校园建设",
            },
        },
        {
            path: "/share-cc-course",
            name: "ShareCollectionCourse",
            component: ShareCollectionCourse,
            meta: {
                title: "“课后三点半”优质课程展示",
            },
        },
        {
            path: "/activity/gm-read",
            name: "GMRead",
            component: GMRead,
            meta: {
                title: "“光明阅读领航人”在线报名",
            },
        },
        {
            path: "/activity/little-reporter-apply",
            name: "little-reporter-apply",
            component: LittleReporterApply1,
            meta: {
                title: "申请加入小记者",
            },
        },
        {
            path: "/activity/little-reporter-apply-2",
            name: "little-reporter-apply-2",
            component: LittleReporterApply2,
            meta: {
                title: "申请成为校记者站",
            },
        },
        {
            path: "/activity/little-reporter-apply-1-1",
            name: "little-reporter-apply-1-1",
            component: LittleReporterApply1_1,
            meta: {
                title: "申请加入小记者",
            },
        },
        {
            path: "/activity/little-reporter-apply-2-1",
            name: "little-reporter-apply-2-1",
            component: LittleReporterApply2_1,
            meta: {
                title: "申请成为校记者站",
            },
        },
        {
            path: "/activity/introduce",
            name: "introduce",
            component: Introduce,
            meta: {
                title: "介绍",
            },
        },
        {
            path: "/activity/book-boy",
            name: "bookBoy",
            component: BookBoy,
            meta: {
                title: "光明小书童",
            },
        },
        {
            path: "/activity/read-story",
            name: "RSIndex",
            component: RSIndex,
            meta: {
                title: "“最美红色故事发声人”",
            },
            children: [
                {
                    path: "display",
                    name: "RSDisplay",
                    component: RSDisplay,
                    meta: {
                        title: "“最美红色故事发声人”作品展示",
                    },
                },
                {
                    path: "enroll",
                    name: "RSEnroll",
                    component: RSEnroll,
                    meta: {
                        title: "“最美红色故事发声人”短视频、征文报名",
                    },
                },
                {
                    path: "introduce",
                    name: "RSIntroduce",
                    component: RSIntroduce,
                    meta: {
                        title: "“最美红色故事发声人”活动介绍",
                    },
                },
                {
                    path: "video-detail",
                    name: "RSVideoDetail",
                    component: RSVideoDetail,
                    meta: {
                        title: "“最美红色故事发声人”短视频详情",
                    },
                },
                {
                    path: "article-detail",
                    name: "RSArticleDetail",
                    component: RSArticleDetail,
                    meta: {
                        title: "“最美红色故事发声人”征文详情",
                    },
                },
                {
                    path: "notice",
                    name: "RSNotice",
                    component: RSNotice,
                    meta: {
                        title: "“最美红色故事发声人”获奖通知",
                    },
                },
            ],
        },
        {
            path: "/activity/read-story-2024",
            name: "RS2024Index",
            component: RS2024Index,
            meta: {
                title: "重庆市青少年红色故事发声人",
            },
            children: [
                {
                    path: "display",
                    name: "RS2024Display",
                    component: RS2024Display,
                    meta: {
                        title: "重庆市青少年红色故事发声人",
                        keepAlive: true,
                    },
                },
                {
                    path: "enroll",
                    name: "RS2024Enroll",
                    component: RS2024Enroll,
                    meta: {
                        title: "重庆市青少年红色故事发声人",
                    },
                },
                {
                    path: "introduce",
                    name: "RS2024Introduce",
                    component: RS2024Introduce,
                    meta: {
                        title: "重庆市青少年红色故事发声人",
                    },
                },
                {
                    path: "video-detail",
                    name: "RS2024VideoDetail",
                    component: RS2024VideoDetail,
                    meta: {
                        title: "重庆市青少年红色故事发声人",
                    },
                },
                {
                    path: "article-detail",
                    name: "RS2024ArticleDetail",
                    component: RS2024ArticleDetail,
                    meta: {
                        title: "重庆市青少年红色故事发声人",
                    },
                },
                {
                    path: "trends",
                    name: "RS2024Trends",
                    component: RS2024Trends,
                    meta: {
                        title: "重庆市青少年红色故事发声人",
                    },
                },
                {
                    path: "trends-detail",
                    name: "RS2024TrendsDetail",
                    component: RS2024TrendsDetail,
                    meta: {
                        title: "重庆市青少年红色故事发声人",
                    },
                },
            ],
        },
        {
            path: "/activity/carnival",
            name: "carnivalIndex",
            component: carnivalIndex,
            meta: {
                title: "光明小记者嘉年华",
            },
            children: [
                {
                    path: "display",
                    name: "carnivalDisplay",
                    component: carnivalDisplay,
                    meta: {
                        title: "光明小记者嘉年华",
                        keepAlive: true,
                    },
                },
                {
                    path: "enroll",
                    name: "carnivalEnroll",
                    component: carnivalEnroll,
                    meta: {
                        title: "光明小记者嘉年华",
                    },
                },
                {
                    path: "introduce",
                    name: "carnivalIntroduce",
                    component: carnivalIntroduce,
                    meta: {
                        title: "光明小记者嘉年华",
                    },
                },
                {
                    path: "video-detail",
                    name: "carnivalVideoDetail",
                    component: carnivalVideoDetail,
                    meta: {
                        title: "光明小记者嘉年华",
                    },
                },
                {
                    path: "article-detail",
                    name: "carnivalArticleDetail",
                    component: carnivalArticleDetail,
                    meta: {
                        title: "光明小记者嘉年华",
                    },
                },
                {
                    path: "trends",
                    name: "carnivalTrends",
                    component: carnivalTrends,
                    meta: {
                        title: "光明小记者嘉年华",
                    },
                },
                {
                    path: "trends-detail",
                    name: "carnivalTrendsDetail",
                    component: carnivalTrendsDetail,
                    meta: {
                        title: "光明小记者嘉年华",
                    },
                },
            ],
        },
        {
            path: "/privacy",
            name: "privacy",
            component: Privacy,
            meta: {
                title: "隐私政策",
            },
        },
        {
            path: "/share-download",
            name: "shareDownload",
            component: ShareDownload,
            meta: {
                title: "分享下载页",
            },
        },
        {
            path: "/share-workshops-task",
            name: "ShareWorkshopsTask",
            component: ShareWorkshopsTask,
            meta: {
                title: "中小学研修班作业",
            },
        },
        {
            path: "/lenovo-event",
            name: "lenovoEvent",
            component: LenovoEvent,
            meta: {
                title: "联想智慧教育",
            },
        },
        {
            path: "/lenovo-event/enroll",
            name: "lenovoEventEnroll",
            component: LenovoEventEnroll,
            meta: {
                title: "报名",
            },
        },
        // 商城
        {
            path: "/shop",
            name: "shop",
            component: Shop,
            meta: {
                title: "商城",
            },
            children: [
                {
                    path: "training-camp",
                    name: "TrainingCampGoods",
                    component: TrainingCampGoods,
                    meta: {
                        title: "训练营",
                    },
                },
                {
                    path: "training-camp/createOrder",
                    name: "TrainingCampCreateOrder",
                    component: TrainingCampCreateOrder,
                    meta: {
                        title: "创建订单-训练营",
                    },
                },
                {
                    path: "training-camp/order-success",
                    name: "TrainingCampOrderSuccess",
                    component: TrainingCampOrderSuccess,
                    meta: {
                        title: "支付成功-训练营",
                    },
                },
            ],
        },
        {
            path: "/official/home",
            name: "officialHome",
            component: OfficialHome,
            meta: {
                title: "官网首页",
            },
        },
        {
            path: "/official/profile",
            name: "OfficialProfile",
            component: OfficialProfile,
            meta: {
                title: "公司简介",
            },
        },
        {
            path: "/official/business",
            name: "OfficialBusiness",
            component: OfficialBusiness,
            meta: {
                title: "公司业务",
            },
        },
        {
            path: "/official/cases/digitaliz",
            name: "OfficialCasesDigitalize",
            component: OfficialCasesDigitalize,
            meta: {
                title: "国企数字化",
            },
        },
        {
            path: "/official/cases/edu",
            name: "OfficialCasesEdu",
            component: OfficialCasesEdu,
            meta: {
                title: "智慧教育",
            },
        },
        {
            path: "/art/team",
            name: "ArtTeam",
            component: ArtTeam,
            meta: {
                title: "作品展示",
            },
        },
        {
            path: "/art/video",
            name: "ArtVideo",
            component: ArtVideo,
            meta: {
                title: "作品展示",
            },
        },
    ],
})
