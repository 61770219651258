<template>
    <div class="rs-introduce-container">
        <img 
        class="content"
        src="../../../../public/static/images/activity/carnival/eventDetails.jpg"
        />
    </div>
</template>

<script>
import { AuthMixin } from "@/assets/utils/mixin"
import Clipboard from "clipboard"
import { Notify } from "vant"
export default {
    mixins: [AuthMixin],
    name: "RSIntroduce",
    data() {
        return {}
    },
    created() {
        const clipboard = new Clipboard("#downloadUrl")
        clipboard.on("success", () => {
            Notify({ type: "success", message: "地址复制成功" })
        })
    },
    mounted() {
        this.initWxConfig({
            itemName: "“光明小记者嘉年华”视频、征文报名",
            desc: "光明小记者嘉年华”视频、征文报名",
            pic: "https://xst.olomobi.com/img/top.66b4c33c.jpg",
            link: `activity/carnival/introduce`,
        })
    },
    methods: {
        downloadTemplate() {
            window.location.href = "http://47.99.57.208:9528/template.xlsx"
        },
    },
}
</script>

<style lang="less" scoped>
.rs-introduce-container {
    .content {
        margin: 0px;
        margin-top: -4px;
        background-color: #56b0fe;
    }
}
</style>
