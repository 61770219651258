// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue'
import App from './App'
import router from './router'
import '@/assets/css/reset.css'

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import { Cascader } from 'vant'
import { Picker } from 'vant'
import { Popup } from 'vant'
import { Uploader } from 'vant'
import { Area } from 'vant'
import { Dialog, DatetimePicker, Notify, Tag, Icon, Form, Field, RadioGroup, Radio, Button, Cell, CellGroup, Swipe, SwipeItem, List, Toast, Sticky, Tab, Tabs, Tabbar, TabbarItem, Image as VanImage } from 'vant'

const app = createApp(App)
app.use(VueViewer)
app.use(router)

// vant组件
app.use(Cascader)
app.use(Picker)
app.use(Popup)
app.use(Uploader)
app.use(Area)
app.use(DatetimePicker)
app.use(Notify)
app.use(Form)
app.use(Field)
app.use(RadioGroup)
app.use(Radio)
app.use(Button)
app.use(Cell)
app.use(CellGroup)
app.use(Swipe)
app.use(SwipeItem)
app.use(List)
app.use(Toast)
app.use(Sticky)
app.use(Tab)
app.use(Tabs)
app.use(Tabbar);
app.use(TabbarItem);
app.use(VanImage);
app.use(Tag);
app.use(Icon);
app.use(Dialog);

router.beforeEach((to, from, next) => {
  if (to && to.name === 'VoteHome') {
    if (!from || !from.name) {
      // next('/?fromId=VoteHome');
      // return
    }
  }

  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})


app.mount('#app')
