import apiCommon from "../assets/api/api_common";

export const PIC_URL = 'https://weixinfactory.di1game.com/'
export const DEFAULT_AVATAR = 'https://weixinfactory.di1game.com/weixinfactory/Icon/20190904/142231/3bd782e59c5bd44b069ba7bd7364b69c.jpeg'
export const packageName = 'com.olomobi.edutopapp'
export const USER_INFO = 'Educk-UserInfo'

export function onScrollToBottom(callback) {
  window.onscroll = function(){
    let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
    let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
    let scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
    if(scrollTop+windowHeight == scrollHeight){
      if (callback) {
        callback()
      }
    }
  }
}

export function uuid() {
  let s = [];
  let hexDigits = "0123456789abcdef";
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  return s.join('');
}


export function getFullPicUrl(url) {
  if (!url) {
    return ''
  }
  if (url.startsWith('http')) {
    return url
  }
  return PIC_URL + url
}

export function getPicList (list) {
  if (typeof list === 'string') {
    try {
      return JSON.parse(list).map(it => {
        return it.startsWith('http') ? it : PIC_URL + it
      })
    } catch (e) {
      return []
    }
  } else {
    return list.map(it => {
      return it.startsWith('http') ? it : PIC_URL + it
    })
  }
}

function formatNumber (n) {
  const str = n.toString()
  return str[1] ? str : `0${str}`
}

export function formatDate (date) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const t1 = [year, month, day].map(formatNumber).join('-')
  return `${t1}`
}

export function formatSeconds (endTime) {
  let secondTime = parseInt(endTime)//将传入的秒的值转化为Number
  let min = 0// 初始化分
  let h = 0// 初始化小时
  let result = ''
  if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
    min = parseInt(secondTime / 60)//获取分钟，除以60取整数，得到整数分钟
    secondTime = parseInt(secondTime % 60)//获取秒数，秒数取佘，得到整数秒数
    if (min > 60) {//如果分钟大于60，将分钟转换成小时
      h = parseInt(min / 60)//获取小时，获取分钟除以60，得到整数小时
      min = parseInt(min % 60) //获取小时后取佘的分，获取分钟除以60取佘的分
      result = `${h.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${secondTime.toString().padStart(2, '0')}`
    } else {
      result = `${min.toString().padStart(2, '0')}:${secondTime.toString().padStart(2, '0')}`
    }
  } else {
    result = '00:' + secondTime.toString().padStart(2, '0')
  }
  return result
}

export function tagAddStyle (htmlText) {
  return htmlText.replace(/<[img|section][^>]*>/gi, (match) => {
    if (match.indexOf('style') !== -1) {
      var regex1 = new RegExp('style=[‘\'"](.*)width:(.*?)px;(.*)[‘\'"]', 'gmi')
      // eslint-disable-next-line no-unused-vars
      match = match.replace(regex1, (text, $1, $2, $3) => {
        if (Number($2) > 295) {
          text = text.replace(regex1, 'style="$1$3width:100% !important;"')
        }
        return text
      })
      return match.replace(/style\s*?=\s*?([‘'"])([\s\S]*?)\1/ig, 'style="max-width:100% !important;$2"') // 替换style
    } else {
      // eslint-disable-next-line
      let regex1 = new RegExp('(i?)(\<img)(?!(.*?style=[‘\'"](.*)[‘\'"])[^\>]+\>)', 'gmi')
      match = match.replace(regex1, '$2 style=""$3')
      // eslint-disable-next-line
      let regex2 = new RegExp('(i?)(\<img.*?style=[‘\'"])([^\>]+\>)', 'gmi')
      match = match.replace(regex2, '$2max-width:100% !important;$3')
      return match
    }
  })
}

export function timePeriod (createTime, type) {
  // createTime ------- 一般时间格式
  // console.log('传type 分类型 为1 区分所有资讯Item 的发布时间 控制在3天内', createTime)
  let time = 0
  let nowTime = Math.floor(Date.now() / 1000)
  let pastTime = Math.floor(new Date(createTime).valueOf() / 1000)
  let timeVal = nowTime - pastTime
  if (timeVal > 7 * 24 * 60 * 60) {
    time = formatDate(new Date(createTime))
    // if (!type) {
    //
    // }
    // else {
    //   time = '3天前'
    // }
  } else if (timeVal >= 24 * 3600 && timeVal <= 7 * 24 * 3600) {
    let val = Math.floor(timeVal / (24 * 3600))
    if (!type) {
      time = val + '天前'
    } else {
      time = (val >= 3 ? 3 : val) + '天前'
    }
  } else if (timeVal >= 3600 && timeVal <= 24 * 3600) {
    let val = Math.floor(timeVal / 3600)
    time = val + '小时前'
  } else if (timeVal >= 300 && timeVal <= 3600) {
    let val = Math.floor(timeVal / 60)
    time = val + '分钟前'
  } else {
    time = '刚刚发布'
  }
  return time
}

export function isWeixin() {
  let ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') != -1;
}

export function detect(){
  var equipmentType = "";
  var agent = navigator.userAgent.toLowerCase();
  var android = agent.indexOf("android");
  var iphone = agent.indexOf("iphone");
  var ipad = agent.indexOf("ipad");
  if(android != -1){
    equipmentType = "android";
  }
  if(iphone != -1 || ipad != -1){
    equipmentType = "ios";
  }
  return equipmentType;
}

export function jump(component, page, param, showHint) {
  let query = component.$route.query

  param = encodeURIComponent(JSON.stringify(param))
  let equipmentType = detect()
  let link = ''

  if (equipmentType === 'ios') {
    // 跳转universal link
    if (page) {
      link = `https://edu.olomobi.com/wx_conn?page=${page}&action=open&param=${param}&skipType=1`
    } else {
      link = `https://edu.olomobi.com/wx_conn`
    }
    if (query.sid) {
      link = safeAddQuery(link, 'sid', query.sid)
    }
    window.location.href = link
  } else if (equipmentType === 'android') {
    if (isWeixin()) {
      if (showHint) {
        showHint()
      }
    } else {
      if (page) {
        link = `edutop://main/home?page=${page}&action=open&param=${param}&skipType=1`
      } else {
        link = `edutop://main/home`
      }
      if (query.sid) {
        link = safeAddQuery(link, 'sid', query.sid)
      }
      window.location.href = link
      apiCommon.getAppVersion({platform: 'android'}).then(res => {
        if (res.errcode !== 0) {
          const userAgent = navigator.userAgent; // 获取 userAgent 字符串
          if (/HUAWEI|Honor/.test(userAgent)) {
            // 华为设备跳转到华为商店
            window.location.href = `appmarket://details?id=${packageName}`;
          } else if (/XiaoMi|MiuiBrowser/.test(userAgent)) {
            // 小米设备跳转到小米商店
            window.location.href = `mimarket://details?id=${packageName}`;
          } else if (/OPPO|HeyTap/.test(userAgent)) {
            // OPPO 设备跳转到 OPPO 商店
            window.location.href = `oppomarket://details?id=${packageName}`;
          } else if (/VIVO/.test(userAgent)) {
            // vivo 设备跳转到 vivo 商店
            window.location.href = `vivomarket://details?id=${packageName}`;
          } else {
            window.location.href= `https://sj.qq.com/appdetail/${packageName}`
          }
        }
        window.setTimeout(function() {
          window.location.href = res.data.apkUrl
        }, 800)
      })
    }
  } else {
    // 不支持的网页类型
  }
  copyContentH5(link)
}

export function jumpPck(component, page, param, showHint) {
  let query = component.$route.query

  param = encodeURIComponent(JSON.stringify(param))
  let equipmentType = detect()
  let link = ''

  if (equipmentType === 'ios') {
    // 跳转universal link
    if (page) {
      link = `https://pckapp.com?page=${page}&action=open&param=${param}&skipType=1`
    } else {
      link = `https://pckapp.com`
    }
    if (query.sid) {
      link = safeAddQuery(link, 'sid', query.sid)
    }
    window.location.href = link
    window.setTimeout(function() {
      window.location.href = 'https://apps.apple.com/cn/app/%E5%B0%8F%E5%AD%94%E9%9B%80%E6%95%99%E8%82%B2%E5%B9%B3%E5%8F%B0/id1558773391'
    }, 800)
  } else if (equipmentType === 'android') {
    if (isWeixin()) {
      if (showHint) {
        showHint()
      }
    } else {
      if (page) {
        link = `pckapp://main/home?page=${page}&action=open&param=${param}&skipType=1`
      } else {
        link = `pckapp://main/home`
      }
      if (query.sid) {
        link = safeAddQuery(link, 'sid', query.sid)
      }
      window.location.href = link
      // window.setTimeout(function() {
      //   window.location.href = 'https://pckapp.oss-cn-hangzhou.aliyuncs.com/app/download/pck_20210818_2.apk'
      // }, 800)
      apiCommon.getPCKAppVersion({platform: 'android'}).then(res => {
        if (res.errcode !== 0) {
          return
        }
        window.setTimeout(function() {
          window.location.href = res.data.apkUrl
        }, 800)
      })
    }
  } else {
    // 不支持的网页类型
  }
  copyContentH5(link)
}

export function safeAddQuery(url, key, value) {
  if (!url) {
    return url
  }
  if (url.indexOf('?') !== -1) {
    url = `${url}&${key}=${value}`
  } else {
    url = `${url}?${key}=${value}`
  }

  return url
}

function copyContentH5(content) {
  var copyDom = document.createElement('div');
  copyDom.innerText = content;
  copyDom.style.position='absolute';
  copyDom.style.top='0px';
  copyDom.style.right='-9999px';
  document.body.appendChild(copyDom);
  //创建选中范围
  var range = document.createRange();
  range.selectNode(copyDom);
  //移除剪切板中内容
  window.getSelection().removeAllRanges();
  //添加新的内容到剪切板
  window.getSelection().addRange(range);
  //复制
  var successful = document.execCommand('copy');
  copyDom.parentNode.removeChild(copyDom);
  try{
    var msg = successful ? "successful" : "failed";
    console.log('Copy command was : ' + msg);
  } catch(err){
    console.log('Oops , unable to copy!');
  }
}

export function formatViewCount(viewCount) {
  if (viewCount < 10000) {
    return viewCount
  }
  if (viewCount >= 100000000) {
    return (viewCount / 100000000).toFixed(1) + '亿'
  }

  return (viewCount / 10000).toFixed(1) + '万'
}

export function strlen(str, size) {
  var len = 0;
  for (var i = 0; i < str.length; i++) {
    var c = str.charCodeAt(i);
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
      len++;
    } else {
      len += 2;
    }
  }
  return (len / 2) * size;
}
export function subLine(str, size, maxWidth) {
  maxWidth -= (size * 1.5)
  let width = strlen(str, size)
  let totalWidth = 0
  let result = ''
  // console.log('!!!!', width)
  if (width > maxWidth) {
    for (let i = 0; i < str.length; ++i) {
      let c = str.charAt(i)
      let cWidth = strlen(c, size)
      if (totalWidth + cWidth > maxWidth) {
        return {txt: result + '...', ec: true}
      } else {
        result += c
        totalWidth += cWidth
      }
    }
    return {txt:  result + '...', ec: true}
  } else {
    return {txt: str, ec: false}
  }
}